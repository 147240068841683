import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { editPromo, updateStage, createPromo } from "actions/promotions/index";
import { Drawer, Button, Radio, Spin } from "antd";
import "./createPromoNew.css";
import ViewActivity from "./ViewActivity";
import StatusDrawer from "components/common/statusDrawer";
import CreateStaticForm from "./CreateStaticForm";
import Teams from "./teams";
import RejectionModal from "./RejectionModal";
import session from "utils/session";
import { QuestionStatus } from "utils/constants";
import SaticQuestionForm from "./staticQuestionForm";
import PromoService from "services/promo-service/promo-service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import ReactHtmlParser from 'react-html-parser';
import useSWR, { mutate } from 'swr';

const EditPromo = (props) => {
  const [data, setData] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const dispatch = useDispatch();
  const [stages, setStages] = useState([]);
  const [currentStage, setCurrentStage] = useState(0);
  const [staticQuestions, setStaticQuestions] = useState([]);
  const [mode, setMode] = useState("promo");
  const [loadingState] = useState(false);
  const [showCreateAlert, setShowCreateAlert] = useState("");
  const [approveflow, setApproveFlow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();
  const teamsRef = useRef();
  const staticQuestionsRef = useRef();
  const userId = session.userId;
  const isAdmin = session.role === "Admin";
  const [isApproved, setIsApproved] = useState(false);
  const { showAlert } = useAlert();
  const promoService = useMemo(() => new PromoService(), []);

  useEffect(() => {
    if (props?.data) {
      setData(props?.data);
      if (props?.data?.stages) {
        setStages(props?.data?.stages);
      }
      if (props?.data?.staticQuestions) {
        setStaticQuestions(props?.data?.staticQuestions);
      }
    } else {
      getPromoDetails(props?.editId);
    }
  }, [props?.data]);

  const getPromoDetails = async (promoId = "") => {
    await promoService.getPromoDetailsById(promoId).then((resp) => {
      setData(resp?.data);

      if (resp?.data?.stages) {
        setStages(resp?.data?.stages);
      }
      if (resp?.data?.staticQuestions) {
        setStaticQuestions(resp?.data?.staticQuestions);
      }
    })
      .catch((error) => console.error("Error fetching promo details:", error));
  }

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const myTeamIndex = useMemo(() => {
    return isAdmin ? 0 : stages.findIndex((i) => i.teamId === session.teamId);
  }, [stages, isAdmin]);
  const handleSubmit = async (e, approveAlso) => {
    if (e === QuestionStatus.Submitted) {
      const isFormValid = formRef.current.isValid();
      const isTeamValid = teamsRef.current.isValid();
      const isStaticQuestionValid = staticQuestionsRef.current.isValid();
      if (!isTeamValid || !isFormValid || !isStaticQuestionValid) {
        return;
      }
    }
    const payload = formRef.current.getFormData();
    payload.stages = teamsRef.current.getStagesData();
    payload.staticQuestions = staticQuestionsRef.current.getStaticQuestionData();
    let currentTeam = {};
    if (e === QuestionStatus.Incomplete) {
      setLoadingSave(true);
    } else if (e === QuestionStatus.Submitted) {
      setLoading(true);
      currentTeam = data.stages?.find((i) => i.teamId === data.actionTeam);
      const nexTeam = data.stages?.find((i) => i?.stage === currentTeam?.stage + 1);
      if (nexTeam?.teamId) {
        payload.actionTeam = nexTeam?.teamId;
        payload.actionTeamName = nexTeam?.name;
      }
    }
    payload.isDraft = e === QuestionStatus.Incomplete;
    let res = {};
    if (currentTeam.stage === 0 && data.isDraft) {
      payload._id = data?._id;
      res = await dispatch(createPromo(payload));
      if (!res.error) {
        const promoId = data?._id;
        let loading = false;
        if (res.payload?.data?.data.status === "Pending") {
          const id = res.payload?.data?.data._id;
          const pollUpdateStageAndStatus = async () => {
            try {
              setLoadingSave(true);
              setLoading(true);
              const response = await promoService.getPromoStatus(id);
              const status = response?.data?.status;
              if (status === "Success") {
                const updateStagePayload = {
                  teamId: stages[0].teamId,
                  status: QuestionStatus.Submitted,
                };
                await dispatch(updateStage(promoId, updateStagePayload));
                setLoadingSave(false);
                setLoading(false);
                loading = false;
                setShowCreateAlert(true);
                mutate({ key: "promoStatus", id: id });
                return;
              } else if (status === "Failed") {
                setLoadingSave(false);
                setLoading(false);
                loading = false;
                mutate({ key: "promoStatus", id: id });
                showAlert({
                  message: ReactHtmlParser(response.message),
                  variant: AlertVariant.ERROR,
                  duration: 0,
                });
                return;
              } else {
                // Continue polling
                setLoadingSave(true);
                setLoading(true);
                if (!loading) {
                  loading = true;
                  showAlert({
                    message: ReactHtmlParser(response.message),
                    variant: AlertVariant.INFO,
                    duration: 0,
                  });
                }
                setTimeout(pollUpdateStageAndStatus, 5000);
              }
            } catch (err) {
              setLoadingSave(false);
              setLoading(false);
              console.error("Error during stage/status polling:", err);
              showAlert({
                message: "An error occurred during submission.",
                variant: AlertVariant.ERROR,
                duration: 0,
              });
            }
          };

          pollUpdateStageAndStatus();
        } else {
          setLoadingSave(false);
          setLoading(false);
          setShowCreateAlert(true);
          // Handle non-pending status
          const updateStagePayload = {
            teamId: stages[0].teamId,
            status: QuestionStatus.Submitted,
          };
          await dispatch(updateStage(promoId, updateStagePayload));
          mutate({ key: "stageData", id: promoId }, (currentData) => ({
            ...currentData,
            teamId: updateStagePayload.teamId,
            status: updateStagePayload.status,
          }));
        }
      } else {
        setLoadingSave(false);
        setLoading(false);
        showAlert({
          message: ReactHtmlParser(res?.error?.response?.data?.message),
          variant: AlertVariant.ERROR,
          duration: 0,
        });
      }
    } else {
      res = await dispatch(editPromo(data?._id, payload));
      if (res.error === undefined) {
        if (approveAlso) {
          await dispatch(
            updateStage(data._id, {
              teamId: stages[currentStage || 0].teamId,
              status: QuestionStatus.Approved,
            })
          );
        } else {
          await dispatch(
            updateStage(res.payload?.data?.data._id, {
              teamId: stages[myTeamIndex || 0]?.teamId,
              status: QuestionStatus.Submitted,
            })
          );
        }
        setShowCreateAlert("Promo data has been submitted successfully ");
      } else {
        const message = res?.error?.response?.data?.message;
        showAlert({
          message: ReactHtmlParser(message),
          variant: AlertVariant.ERROR,
          duration: 0,
        });
      }
    }

  };

  const handleApprove = async () => {
    const res = await dispatch(
      updateStage(data._id, {
        teamId: stages[currentStage].teamId,
        status: QuestionStatus.Approved,
      })
    );
    if (res.error === undefined) {
      setIsApproved(true);
      setApproveFlow(true);
      setShowCreateAlert("Promo data has been approved successfully ");

      // Updated current stage status directly in stages array
      setStages(prevStages =>
        prevStages.map((stage, index) =>
          index === currentStage ? { ...stage, status: QuestionStatus.Approved } : stage
        )
      );

      if (res?.payload?.data?.data) {
        setData((prev) => ({
          ...res?.payload?.data?.data,
          owner: prev.owner,
          adset: prev.adset,
        }));
      }
    }
  };

  const onRejectClick = () => {
    // Check if the current user's team matches the current action team
    const isCurrentPendingActionTeam = stages[myTeamIndex]?.teamId === session.teamId;
    if (isCurrentPendingActionTeam) {
      setShowModal(true); // Open rejection modal if team is authorized
    } else {
      console.warn("Only the current action team can reject.");
    }
  };

  const updateStages = useCallback((stages) => {
    // setStages(stages);
  }, []);
  const updateStaticQuestion = useCallback((questions) => {
    // setStaticQuestions(questions);
  }, [])
  const renderSubmitFooter = (isLast, showDraftButton = false) => {
    return (
      <>
        {showDraftButton ? (
          <Button
            type="link"
            onClick={() => handleSubmit("Incomplete")}
            disabled={loading || loadingSave}
          >
            {loadingSave ? `Loading...` : "Save as draft"}
          </Button>
        ) : (
          <div />
        )}
        <div>
          {" "}
          <Button
            onClick={() => isApproved ? props.onClose({ refresh: true }) : props.onClose()}
            disabled={loading || loadingSave}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSubmit("Submitted", isLast);
            }}
            disabled={loading || loadingSave}
            className="next-btn"
          >
            {loading ? `Loading` : isLast ? "Submit & Approve" : "Submit"}
          </Button>
        </div>
      </>
    );
  };

  const renderRejectFooter = () => {
    const isCurrentPendingActionTeam = stages[currentStage]?.teamId === session.teamId;
    return (
      <>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <div>
          {isCurrentPendingActionTeam && (
            <Button
              onClick={() => onRejectClick()}
              disabled={loading || loadingSave}
            >
              {loading ? `Loading...` : "Reject"}
            </Button>
          )}
        </div>
      </>
    );
  };

  const renderApproveFooter = () => {
    return (
      <>
        <Button type="link"
          onClick={() => isApproved ? props.onClose({ refresh: true }) : props.onClose()}
        >
          Cancel
        </Button>
        <div>
          <Button
            onClick={() => onRejectClick()}
            disabled={loading || loadingSave}
          >
            {loading ? `Loading...` : "Reject"}
          </Button>
          <Button
            type="primary"
            onClick={() => handleApprove()}
            className="next-btn"
          >
            {loading ? `Loading...` : "Approve"}
          </Button>
        </div>
      </>
    );
  };
  const renderFooter = () => {
    return (
      <>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <div></div>
      </>
    );
  };
  const footer = () => {
    const isDraft = data?.isDraft;
    const currentTeam = data.stages?.find((i) => i.teamId === data.actionTeam);
    const isCurrentPendingActionTeam =
      currentTeam?.teamId &&
      session.teamId &&
      currentTeam?.teamId === session.teamId;
    if (!stages.length) {
      return null;
    }
    if (data?.promoStatus === QuestionStatus.Approved) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }
    if (isDraft) {
      if (data?.createdBy === userId || isCurrentPendingActionTeam) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderSubmitFooter(false, true)}
          </div>
        );
      }
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }

    if (
      [QuestionStatus.Submitted, QuestionStatus.Approved].includes(
        stages?.[myTeamIndex]?.status
      )
    ) {
      if (currentStage > myTeamIndex) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderFooter()}
          </div>
        );
      }
    }

    if (
      [QuestionStatus.Approved, QuestionStatus.Rejected].includes(
        stages[currentStage]?.status
      )
    ) {
      if (currentStage < myTeamIndex) {
        if (stages[currentStage]?.status === QuestionStatus.Approved) {
          return (
            <div className="flex space-between w-full create-footer">
              {renderRejectFooter()}
            </div>
          );
        }
        if (stages[currentStage]?.status === QuestionStatus.Rejected) {
          return (
            <div className="flex space-between w-full create-footer">
              {renderFooter()}
            </div>
          );
        }
      }
      if (
        currentStage === myTeamIndex &&
        stages[currentStage]?.status === QuestionStatus.Rejected
      ) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderSubmitFooter()}
          </div>
        );
      }
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }

    if (currentStage === myTeamIndex - 1 && stages[currentStage]?.status === QuestionStatus.Submitted) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderApproveFooter()}
        </div>
      );
    }
    if (
      stages[Math.max(myTeamIndex - 1, 0)]?.status === QuestionStatus.Submitted
    ) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }

    if (currentStage === myTeamIndex) {
      if (stages[currentStage]?.status === QuestionStatus.Submitted) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderFooter()}
          </div>
        );
      }
      if (stages[myTeamIndex - 1]?.status === QuestionStatus.Approved) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderSubmitFooter(currentStage === stages.length - 1)}
          </div>
        );
      }
      else if (stages[myTeamIndex - 1]?.status === QuestionStatus.Submitted) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderApproveFooter()}
          </div>
        );
      }

    }
  };

  const getActionType = () => {
    const isDraft = data?.isDraft;
    // Check if the current stage matches the user's team
    const currentTeam = data.stages?.find((i) => i.teamId === data.actionTeam);
    // const isCurrentPendingActionTeam = stages[currentStage]?.teamId === session.teamId;
    const currentPendingTeam = currentTeam?.teamId === session?.teamId;
    if (isDraft) {
      return data?.createdBy === userId || currentPendingTeam ? "edit" : "view";
    }

    // Allow only the current pending action team to edit or approve
    if (currentPendingTeam) {
      return "edit";
    }

    if (
      currentStage === Math.max(0, myTeamIndex - 1) &&
      stages[currentStage]?.status === QuestionStatus.Submitted
    ) {
      return "edit";
    }
    if (
      (myTeamIndex === currentStage &&
        [QuestionStatus.Submitted, QuestionStatus.Approved].includes(
          stages[currentStage]?.status
        )) ||
      stages[myTeamIndex - 1]?.status === QuestionStatus.Submitted ||
      currentStage < myTeamIndex
    ) {
      return "view";
    }

    return "view";
  };

  const getStaticQuestionActionType = () => {
    const isCurrentPendingActionTeam = "edit"; //data.actionTeam === session.teamId;
    return isCurrentPendingActionTeam ? "edit" : "view";
  }
  const firstTeam = data?.stages[0]?.teamId === session.teamId;

  const isFirstTeam = firstTeam && data?.stages[0]?.status === QuestionStatus.Rejected;

  return (
    <div>
      <Drawer
        title={`Edit Offer`}
        width={640}
        height={1900}
        onClose={() => {
          if (!(loading || loadingSave)) {
            isApproved ? props.onClose({ refresh: true }) : props.onClose()
          }

        }}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={footer()}
        maskClosable={!(loading || loadingSave)}
      >
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{
            marginBottom: 8,
            width: "100%",
            display: "flex",
          }}
        >
          <Radio.Button value="promo" style={{ width: "100%" }}>
            Offer
          </Radio.Button>
          <Radio.Button value="activity" style={{ width: "100%" }}>
            Activity log
          </Radio.Button>
        </Radio.Group>
        {!loadingState && mode === "promo" && (
          <>
            <CreateStaticForm
              ref={formRef}
              setStages={updateStages}
              setStaticQuestions={updateStaticQuestion}
              data={data}
              disabled={true}
              type="edit"
            />

            <div style={{ marginTop: '15px' }}>
              <SaticQuestionForm
                ref={staticQuestionsRef}
                questions={staticQuestions}
                action={getStaticQuestionActionType()}
                isDraft={data.isDraft}
                isFirstTeam={isFirstTeam}
              />
            </div>

            <Teams
              stages={stages}
              ref={teamsRef}
              isDraft={data ? data.isDraft : false}
              action={getActionType()}
              teamClick={(index) => setCurrentStage(index)}
            />
          </>
        )}
        {loadingState && (
          <div className="errorTipDiv">
            <Spin size="middle" tip="Loading..." />
          </div>
        )}

        {!loadingState && mode === "activity" && (
          <ViewActivity id={props?.data?._id} />
        )}
      </Drawer>
      <RejectionModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        showAlert={(
          message = "The rejection comment have been successfully added!"
        ) => {
          setShowCreateAlert(message);
        }}
        myTeamIndex={myTeamIndex}
        data={data}
        currentStage={currentStage}
        stages={stages}
      />
      {showCreateAlert && (
        <StatusDrawer
          onClose={() => {
            setShowCreateAlert(false);
            if (approveflow) {
              teamsRef.current?.handleStageChange(currentStage + 1);
              setApproveFlow(false);
            } else {
              props.onClose({ refresh: true });
            }
          }}
          title={showCreateAlert}
          visible={showCreateAlert}
          subtitle="You can check at “All promo”"
        />
      )}
    </div>
  );
};

export default EditPromo;
