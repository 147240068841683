import * as ActionTypes from "./action-types";
import { SORT_ORDER } from "../promotions";
import qs from "qs";

export const updateLocalErrorTicketJSON = (errorTickets) => ({
  type: ActionTypes.UPDATE_LOCAL_ERROR_TICKET_JSON,
  errorTickets,
});
export const getPromoListNames = () => ({
  type: ActionTypes.GET_PROMO_LIST_NAMES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/promo/names`,
      method: "get",
    },
  },
});

export const getAllErrorTickets = (params = {}) => {

  if (params.orderBy) {
    params.orderBy = SORT_ORDER[params.orderBy];
  }
  let url = `${process.env.REACT_APP_API_URL}/api/errorTicket`;
  // if (params.export) {
  //   url += "?export=true";
  // }
  return {
    type: ActionTypes.GET_ALL_ERROR_TICKET,
    payload: {
      request: {
        url: url,
        method: "get",
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    },
  };
};
export const uploadAttachment = (data) => ({
  type: ActionTypes.UPLOAD_ATTACHMENT,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/media/`,
      method: "post",
      data
    }
  }
})

export const createErrorTickets = (
  title,
  sku,
  date,
  additionalInfo,
  status,
  priority,
  errorType,
  event,
  raisedBy,
  promoName,
  actionOwner,
  masterDataType,
  masterDataTypeValue,
  media,
  comp_id,
  eventId
) => ({
  type: ActionTypes.CREATE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket`,
      method: "post",
      data: {
        title: title,
        promoId: promoName,
        date: date,
        errorTypeId: errorType,
        event: event,
        teamId: actionOwner,
        description: additionalInfo,
        raisedBy: raisedBy,
        skuId: sku,
        masterDataType: masterDataType,
        masterDataTypeValue: masterDataTypeValue,
        media: media,
        comp_id: comp_id,
        eventId: eventId
      },
    },
  },
});

export const getSingleErrorTicket = (id) => ({
  type: ActionTypes.GET_SINGLE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${id}`,
      method: "get",
    },
  },
});

export const updateErrorTicketById = (
  title,
  sku,
  additionalInfo,
  status,
  priority,
  errorType,
  event,
  raisedBy,
  promoName,
  actionOwner,
  _id,
  masterDataType,
  masterDataTypeValue,
  media,
  comp_id,
  eventId
) => ({
  type: ActionTypes.UPDATE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${_id}`,
      method: "patch",
      data: {
        title: title,
        errorTypeId: errorType,
        teamId: actionOwner,
        description: additionalInfo,
        event: event,
        raisedBy: raisedBy,
        skuId: sku,
        promoId: promoName.value,
        masterDataType: masterDataType,
        masterDataTypeValue: masterDataTypeValue,
        media: media,
        comp_id: comp_id,
        eventId: eventId
      },
    },
  },
  meta: {
    errorType: errorType,
    additionalInfo: additionalInfo,
    status: status,
    raisedBy: raisedBy,
    title: title,
    priority: priority,
    _id: _id,
  },
});

export const deleteErrorTicketById = (_id) => ({
  type: ActionTypes.DELETE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${_id}`,
      method: "delete",
    },
  },
  meta: {
    _id: _id,
  },
});

export const updateTicketStatus = (_id, status, msg) => ({
  type: ActionTypes.CHANGE_STATUS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${_id}/validate`,
      method: "patch",
      data: {
        isValidated: false,
        validatorComment: msg,
      },
    },
  },
  meta: {
    status: status,
    _id: _id,
  },
});

export const getCommentAdmin = (id) => ({
  type: ActionTypes.GET_COMMENT_ADMIN,
  id,
});

export const createCommentAdmin = (id, msg) => ({
  type: ActionTypes.CREATE_COMMENT_ADMIN,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${id}/comment`,
      method: "patch",
      data: {
        comment: msg,
      },
    },
  },
});

export const createConfirmAdmin = (id, msg) => {
  let data = {
    isValidated: true,
    approvalStatus: "completed",
  };
  if (msg) {
    data.comment = msg;
  }
  return {
    type: ActionTypes.CREATE_COMMENT_ADMIN,
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${id}`,
        method: "patch",
        data,
      },
    },
  };
};
export const changeApprovalStatus = (id, msg, actionTeamId) => {
  let data = {
    // approvalStatus: "completed",
    "isValidated": true,
  };
    data.actionTeamId = actionTeamId;

  if (msg) {
    data.validatorComment = msg;
  }
  return {
    type: ActionTypes.CREATE_COMMENT_ADMIN,
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/errorTicket/${id}/validate`,
        method: "patch",
        data,
      },
    },
  };
};

export const updateErrorTicketBySearch = (errorTickets) => ({
  type: ActionTypes.UPDATE_ERROR_TICKET_BY_SEARCH,
  errorTickets,
});

export const resetSingleTicket = (errorTickets) => ({
  type: ActionTypes.RESET_SINGLE_TICKET,
  errorTickets,
});

export const getAllErrorTypeValues = () => ({
  type: ActionTypes.GET_ERROR_TYPE_VALUES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/allMsg`,
      method: "get",
    },
  },
});

export const getErrorReportExportData = (data) => ({
  type: ActionTypes.GET_ERROR_EXPORT_DATA,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/download`,
      method: "post",
      data,
    },
  },
});

export const getActionTeam = (id) => ({
  type: ActionTypes.GET_ACTION_TEAM_DATA,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorTicket/promo/${id}/teams`,
      method: "get",
    },
  },
});
