import './footer.css'
import React from 'react'


const Footer = () => {
  return (
    <div className="footer">
      <span className="footer-content">
        ©2025 Illuminz, Inc. All rights reserved.
      </span>

    </div>
  );
}

export default Footer
