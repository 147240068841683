/* eslint-disable no-unused-vars */
import "./TicketReport.css";
import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Fullscreen from "fullscreen-react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Tag,
  Select,
  Alert,
  Spin,
  message,
  Upload
} from "antd";
import {
  CloseCircleOutlined,
  ColumnHeightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  LoadingOutlined,
  ExportOutlined,
  EditOutlined,
  DeleteFilled,
  EyeOutlined, CloudUploadOutlined
} from "@ant-design/icons";
import { Tooltip } from "antd";
import CommonLayout from "components/layout/Layout";
import CustomTable from "components/common/table/TableNew";
import TableSearchDropdown from "components/TableSearchDropdown";
import DateFilter from "components/DateFilter";
import { TICKET_REPORT_LIST_COLUMNS } from "utils/column";
import { Validator } from "utils/validations";
import {
  TextMessage,
  FIELD_REQUIRED,
  SECONDS,
  CircleTickIcon,
  DATE_FORMAT_DASH,
} from "utils/constants";
import session from "utils/session";
import { useNavigate } from "react-router-dom";
import { setPage } from "actions/app";
import {
  createErrorTickets,
  deleteErrorTicketById,
  getAllErrorTickets,
  resetSingleTicket,
  updateErrorTicketById,
  updateErrorTicketBySearch,
  getActionTeam,
  getPromoListNames
} from "actions/ticketReport";
import { getSKUBySearch } from "actions/shelf";
import { getBrand, getClass, getDepartment, getSubclass } from "actions/sku";
import { getAllUsers } from "actions/users/index";
import { getAllErrorType } from "actions/error-type";
import { getAllEvents } from "actions/calendar";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import MediaService from "services/media/media-service";
import TicketReportService from "services/ticket-report/ticketReport-service";
const { Search, TextArea } = Input;
const { Option } = Select;


const TicketReport = () => {
  const [isEnter, setIsEnter] = useState(false);
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState("");
  const loggedInTeam = session && session.teamId ? session.teamId : null;
  const { isAdmin, userId } = session;

  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const errorTickets = useSelector((state) => state.ticketReport.errorTickets);
  const promos = useSelector((state) => state.ticketReport.promos);
  const events = useSelector((state) => state.calendar.events);
  const totalTickets = useSelector((state) => state.ticketReport);
  const errorTypeValues = useSelector((state) => state.errorType.errors);

  const promotionNames = useSelector((state) => state.promo.promoListNames);
  // const users = useSelector((state) => state.ticketReport.actionTeams);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState("");
  const [masterDataType, setMasterDataType] = useState(null);
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState([]);
  const [ticketDate, setTicketDate] = useState(
    moment().format(DATE_FORMAT_DASH)
  );
  const [errorType, setErrorType] = useState(null);
  const [event, setEvent] = useState(null);
  const [eventId, setEventId] = useState("");
  const [priority, setPriority] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [promoName, setPromoName] = useState(null);
  const [comp_id, setCOMPID] = useState("");
  const [promoDetails, setPromoDetails] = useState({});
  const [promoId, setPromoId] = useState("");
  const [actionOwner, setActionOwner] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [ticketDateError, setTicketDateError] = useState("");
  const [errorTypeError, setErrorTypeError] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [promoNameError, setPromoNameError] = useState("");
  const [eventError, setEventError] = useState("");
  const [actionOwnerError, setActionOwnerError] = useState("");
  const [okBtnText, setOkBtnText] = useState("");
  const [tab, setTab] = useState("All Ticket");
  const [successTicketId, setSuccessTicketId] = useState("");
  const [successModalType, setSuccessModalType] = useState("failed");
  const [messageModal, setMessageModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [errorTypeValueOther, setErrorTypeValueOther] = useState("");
  const [errorTypeValueOtherError, setErrorTypeValueOtherError] = useState("");
  const [promoChildren, setpromoChildren] = useState([]);;
  const [fetching, setFetching] = useState(false);
  const [dataSku, setDataSku] = useState([]);
  const [timer, setTimer] = useState(null);
  const [reloadingState] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [masterSubDataList, setMasterSubDataList] = useState([]);
  const [masterSubData, setMasterSubData] = useState([]);
  const [filters, setFilters] = useState({
    perPage: pageSize
  });
  const [masterDataTypeLabel, setMasterDataTypeLabel] = useState("SKU");
  const [promoStatus, setPromoStatus] = useState("");
  useEffect(() => {
    dispatch(setPage("Change Request"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetSingleTicket([]));
  }, [dispatch]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      getData();
      dispatch(getAllErrorType({ perPage: "300" }));
      await dispatch(getPromoListNames());
      await dispatch(getAllUsers());
      await dispatch(getAllEvents());
    };
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (errorTickets) {
      setTickets(errorTickets);
    }
  }, [errorTickets]);

  useEffect(() => {
    if (searchedText === "") {
      dispatch(updateErrorTicketBySearch([]));
    }
  }, [searchedText, dispatch]);

  useEffect(() => {
    setTableLoading(true);
    getData({ page: 1 }, false);
    setCurrPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, searchValue]);

  const getData = async (params = {}, isLoading = true) => {
    if (searchValue) {
      const isNumber = !isNaN(Number(searchValue));
      params.search = isNumber ? { ticketNumber: searchValue } : { title: searchValue };
    }
    if (tab !== "All Ticket") {
      params = {
        ...params,
        actionable: true,
      };
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const merchantId =
      session && session.role.toLowerCase() === "merchant" && session.fullName;
    if (merchantId) {
      params.filters = {
        ...params.filters,
        raisedBy: merchantId,
      };
    }
    const res = await dispatch(getAllErrorTickets(params));
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };
  const fileInputRef = useRef(null);
  const [fileDetails, setFile] = useState(null);
  const mediaService = useMemo(() => new MediaService(), []);
  const ticketReportService = useMemo(() => new TicketReportService(), []);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // const response = await dispatch(uploadAttachment(formData));
      const response = await mediaService.uploadMedia(formData);
      if (response && response?.status) {
        setFile(response?.data);
        showAlert({
          message: response?.message || "File Uploaded successfully",
          variant: AlertVariant.SUCCESS,
        });
      } else {
        showAlert({
          message:
            response?.message || "Something went wrong while uploading...",
          variant: AlertVariant.ERROR,
        });
      }
    } catch (error) {
      showAlert({
        message: "Something went wrong while uploading...",
        variant: AlertVariant.ERROR,
      });
    }
  };

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const getTableActions = (row) => {
    const hasOperationAccess = isAdmin || row?.createdBy === userId;
    return (
      <div className="action-btns-container">
        <Button
          className="btn-style"
          onClick={() => {
            viewErrorTicket(row);
          }}
        >
          <Tooltip title="View">
            <EyeOutlined className="mr-5" />
          </Tooltip>
        </Button>
        <Button
          className="btn-style"
          disabled={!hasOperationAccess}
          onClick={() => updateErrorTicket(row)}
        >
          <Tooltip title="Edit">
            <EditOutlined className="mr-5" />
          </Tooltip>
        </Button>
        <Button
          className="btn-style"
          onClick={() => {
            deleteErrorTicket(row);
          }}
          disabled={!hasOperationAccess}
        >
          <Tooltip title="Delete">
            <DeleteFilled className="mr-5" />
          </Tooltip>
        </Button>
      </div>
    );
  };

  const setupRows = (errorTickets) => {
    return errorTickets?.map((o) => {
      return {
        ticketNumber: o.ticketNumber || "",
        _id: o._id,
        title: o.title,
        promoName: o?.promoName || null,
        raisedBy: o.raisedBy,
        status:
          o.status?.toLowerCase() === "open" ? (
            <Tag color={"red"}>Open</Tag>
          ) : o.status?.toLowerCase() === "rejected" ? (
            <Tag color={"red"}>Rejected</Tag>
          ) : o.status?.toLowerCase() === "cancelled" ? (
            <Tag color={"red"}>Cancelled</Tag>
          ) : (
            <Tag color={"green"}>Resolved</Tag>
          ),
        date: o?.date ? moment(o.date).utc().format(DATE_FORMAT_DASH) : "-",
        priority:
          o.priority?.toLowerCase() === "high" ? (
            <span className="highClr">High</span>
          ) : o.priority?.toLowerCase() === "low" ? (
            <span className="lowClr">Low</span>
          ) : o.priority?.toLowerCase() === "urgent" ? (
            <span className="highClr">Urgent</span>
          ) : (
            <span className="mediumClr">Medium</span>
          ),
        priorityValue: o?.priority,
        ticketStatusValue: o?.status,
        errorType: o?.errorType,
        additionalInfo: o?.description,
        dateValue: o?.date || "",
        isPriority:
          o.priority?.toLowerCase() === "high"
            ? true
            : o.priority?.toLowerCase() === "medium"
              ? false
              : "1",
        isStatus: o.status?.toLowerCase() === "open" ? true : false,
        sku: o.skuId || [],
        promoNameId: o.promoName || "",
        actionOwner: o?.teamDetails?._id || null,
        actionOwnerName: o?.teamDetails?.name || "",
        teamDetails: o?.teamDetails || "",
        errorTypeDetails: o?.errorTypeDetails || "",
        errorTypeName: o?.errorTypeDetails.name,
        promoId: o?.promoId || "",
        validation:
          o.approvalStatus?.toLowerCase() === "pending" ? (
            <Tag color={"orange"}>Pending</Tag>
          ) : (
            <Tag color={"green"}>Completed</Tag>
          ),
        action: getTableActions(o),
        "adsetDetails.name": o?.adsetDetails?.name || "",
        "eventDetails.name": o?.eventDetails?.name || "",
        offerId: o?.offerId || "",
        eventID: o?.eventDetails?.eventId || o?.eventDetails?._id || "",
        masterDataType: o?.masterDataType,
        masterSubData: o?.masterDataTypeValue,
        masterDataTypeLabel: o?.masterDataType,
        media: o?.media,
        comp_id: o?.comp_id,
        RPM_COMP_ID: o?.promoDetails?.rpm_comp_id,
        startDate: o?.promoDetails?.startDate,
        endDate: o?.promoDetails?.endDate,
      };
    });
  };


  const masterDataTypeList = [
    {
      _id: "Brand",
      name: "Brand",
    }, {
      _id: "Class",
      name: "Class",
    }, {
      _id: "Department",
      name: "Department",
    }, {
      _id: "SKU",
      name: "SKU",
    },
    {
      _id: "Subclass",
      name: "Subclass",
    }
  ];

  const handleSearchSkuKeys = async (value) => {
    clearTimeout(timer);

    if (value.length >= 3) {
      const newTimer = setTimeout(async () => {
        setFetching(true);
        setDataSku([]);
        const res = await dispatch(getSKUBySearch(value));
        if (res.error === undefined) {
          setDataSku(res.payload.data.data);
          setFetching(false);
        } else {
          setFetching(false);
        }
      }, 700);
      setTimer(newTimer);
    }
  };

  const handleMasterDataType = (value) => {
    setMasterDataType(value);
    const fetchData = async () => {
      switch (value) {
        case 'SKU':
          setMasterDataTypeLabel('SKU');
          break;
        case 'Department':
          setMasterDataTypeLabel('Department');
          try {
            setFetching(true);
            const res = await dispatch(getDepartment());
            if (!res.error) {
              const listData = res?.payload?.data?.data?.skuList || [];
              const children = listData.map(item => (
                <Option key={item.department_id}>{item.department_desc}</Option>
              ));
              setMasterSubDataList(children);
            }
          } catch (error) {
            console.error('Error fetching department data:', error);
          } finally {
            setFetching(false);
          }
          break;
        case 'Brand':
          setMasterDataTypeLabel('Brand');
          try {
            setFetching(true);
            const res = await dispatch(getBrand());
            if (!res.error) {
              const listData = res?.payload?.data?.data || [];
              const brandChildren = listData.map(item => (
                <Option key={item.sku_brand_desc}>{item.sku_brand_desc}</Option>
              ));
              setMasterSubDataList(brandChildren);
            }
          } catch (error) {
            console.error('Error fetching brand data:', error);
          } finally {
            setFetching(false);
          }
          break;
        case 'Class':
          setMasterDataTypeLabel('Class');
          try {
            setFetching(true);
            const res = await dispatch(getClass());
            if (!res.error) {
              const listData = res?.payload?.data?.data?.skuList || [];
              const classChildren = listData.map(item => (
                <Option key={item.class_id}>
                  {item.class_desc}
                </Option>
              ));
              setMasterSubDataList(classChildren);
            }
          } catch (error) {
            console.error('Error fetching brand data:', error);
          } finally {
            setFetching(false);
          }
          break;
        case 'Subclass':
          setMasterDataTypeLabel('Subclass');
          try {
            setFetching(true);
            const res = await dispatch(getSubclass());
            if (!res.error) {
              const listData = res?.payload?.data?.data?.skuList || [];
              const subClassChildren = listData.map(item => (
                <Option key={item.sub_class_id}>
                  {item.sub_class_desc}
                </Option>
              ));
              setMasterSubDataList(subClassChildren);
            }
          } catch (error) {
            console.error('Error fetching brand data:', error);
          } finally {
            setFetching(false);
          }
          break;
        default:
          break;
      }
    }
    fetchData();

  }
  const renderTag = () => {
    const children = [];
    for (let i = 0; i < errorTypeValues.length; i++) {
      children.push(
        <Option key={errorTypeValues[i]._id}>{errorTypeValues[i].name}</Option>
      );
    }
    for (let i = 0; i < promos.length; i++) {
      if (currentRecord?.eventDetails?._id && promos[i]?.event?._id === currentRecord?.eventDetails?._id) {
        promoChildren.push(<Option key={promos[i]._id}>{promos[i].name}</Option>);
      }
    }

    const eventChildren = events?.length && events?.filter((item) => new Date(item.endDate) >= new Date()).sort((a, b) => new Date(a.startDate) - new Date(b.startDate)).map((item) => (
      <Option key={item._id}>{item.name}</Option>
    ));


    const skuChildren = [];
    if (dataSku.length > 0) {
      for (let i = 0; i < dataSku.length; i++) {
        skuChildren.push(
          <Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>
        );
      }
    }
    const promotionChildren = [];
    if (promotionNames && session.role.toLowerCase() === "admin") {
      for (let i = 0; i < promotionNames.length; i++) {
        promotionChildren.push(
          <Option key={promotionNames[i]._id}>{promotionNames[i].name}</Option>
        );
      }
    }
    if (promotionNames && session.role.toLowerCase() !== "admin") {
      const filtered = promotionNames.filter(
        (item) => item.owner?._id === session.userId
      );
      for (let i = 0; i < filtered.length; i++) {
        promotionChildren.push(
          <Option key={filtered[i]._id}>{filtered[i].name}</Option>
        );
      }
    }

    const usersChildren = [];
    if (users && users[0] !== null && users.length > 0) {
      if (currentAction === "Create Change Request" || currentAction === "Update Change Request") {
        for (let i = 0; i < users.length; i++) {
          if (users[i].name.toLowerCase() == "merch ops" || users[i].name.toLowerCase() == "ecom pricing ops") {
            usersChildren.push(
              <Option key={users[i].teamId}>
                {users[i].name}
              </Option>
            );
          }
        }
      }
    }
    return (
      <form className="userForm" onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <label>
                <span className="spanReq">*</span> Title
              </label>

              <Input
                placeholder="Title"
                value={title}
                className="mt-5"
                onChange={handleTitle}
              />
              <span className="errorLabel">{titleError}</span>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  <span className="spanReq">*</span> Event
                </label>

                <Select
                  showSearch
                  placeholder="Search and select Event"
                  className="w-100 mt-5"
                  optionFilterProp="children"
                  onChange={handleEventChange}
                  value={event}
                  disabled={
                    currentAction === "Update Change Request" ? true : false
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {eventChildren}
                </Select>
                <span className="errorLabel">{eventError}</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  Event ID
                </label>
                <Input
                  placeholder="Event ID"
                  value={eventId}
                  className="mt-5"
                  onChange={handleEventID} disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  <span className="spanReq">*</span> Offer Name
                </label>

                <Select
                  showSearch
                  placeholder="Search and select Offer Name"
                  optionFilterProp="children"
                  onChange={handlePromoName}
                  value={promoName}
                  className="mt-5"
                  disabled={
                    currentAction === "Update Change Request" ? true : false
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {promoChildren}
                </Select>
                <span className="errorLabel">{promoNameError}</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={8}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  Promo ID
                </label>
                <Input
                  placeholder="Promo ID"
                  value={promoId}
                  className="mt-5"
                  onChange={handlePromoID} disabled
                />
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  Comp ID
                </label>
                <Input
                  placeholder="Comp ID"
                  value={comp_id}
                  className="mt-5"
                  onChange={handleCOMPID} disabled
                />
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  RPM Comp ID
                </label>
                <Input
                  placeholder="Comp ID"
                  value={promoDetails?.rpm_comp_id}
                  className="mt-5"
                  onChange={handleCOMPID} disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  Master Data Type
                </label>

                <Select
                  showSearch
                  placeholder="Search and select master data type"
                  optionFilterProp="children"
                  onChange={handleMasterDataType}
                  value={masterDataType}
                  className="mt-5"
                  disabled={
                    currentAction === "Update Change Request" ? true : false
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {masterDataTypeList.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div>
              <label>{masterDataTypeLabel}</label>
              {masterDataTypeLabel.toLowerCase() === "sku" && <Select
                showSearch
                value={masterSubData}
                placeholder={"Search and select SKU"}
                className="w-100 mt-5"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                onChange={handleSku}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? "multiple" : "tags"}
                tokenSeparators={[","]}
                maxTagCount={3}
                disabled={
                  currentAction === "Update Change Request" ? true : false
                }
              >
                {skuChildren}
              </Select>
              }
              {masterDataTypeLabel.toLowerCase() !== "sku" && <Select
                showSearch
                value={masterSubData}
                placeholder={`Search and select ${masterDataTypeLabel}`}
                optionFilterProp="children"
                className="w-100 mt-5"
                onChange={handlemasterSubData}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={
                  currentAction === "Update Change Request" ? true : false
                }
              >
                {masterSubDataList}
              </Select>
              }
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Date
              </label>
              <DatePicker
                onChange={handleDate}
                format={DATE_FORMAT_DASH}
                disabled
                value={
                  ticketDate === ""
                    ? ticketDate
                    : ticketDate?.includes("Z")
                      ? moment(ticketDate)
                      : moment(ticketDate, DATE_FORMAT_DASH)
                }
                showToday={false}
                className="datePic mt-5"
              />

              <span className="errorLabel">{ticketDateError}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Change Type
              </label>

              <Select
                showSearch
                placeholder="Search and select error type"
                optionFilterProp="children"
                className="w-100 mt-5"
                onChange={handleErrorType}
                disabled={
                  currentAction === "Update Change Request" ? true : false
                }
                value={errorType}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {children}
              </Select>
              <span className="errorLabel">{errorTypeError}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Action Team
              </label>
              <Select
                showSearch
                placeholder="Search and select action team"
                optionFilterProp="children"
                onChange={handleActionOwner}
                value={actionOwner}
                className="mt-5 w-100"
                disabled={
                  currentAction === "Update Change Request" ? true : false
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {usersChildren}
              </Select>
              <span className="errorLabel">{actionOwnerError}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                icon={<CloudUploadOutlined />}
                onClick={handleUpload}
              >
                Add Attachment
              </Button>
              <br />
              <a target="_blank" href={fileDetails?.url} rel='noreferrer'>{fileDetails?.originalFilename}</a>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <div>
              <label>Additional Information</label>
              <TextArea
                placeholder="Write Here"
                onChange={handleTextArea}
                className="w-100 mt-5"
                value={textArea}
                rows={3}
              />

            </div>
          </Col>
        </Row>
      </form>
    );
  };

  const handleTitle = (e) => {
    const { value } = e.target;
    setTitleError(Validator.validate("title", value, null, null, true));
    setTitle(value);
  };
  const handleCOMPID = (e) => {
    const { value } = e.target;
    setCOMPID(value);
  }
  const handlePromoID = (e) => {
    const { value } = e.target;
    setPromoId(value);
  }

  const handleEventID = (e) => {
    const { value } = e.target;
    setEventId(value);
  }
  const handleAdd = () => {
    setModalOpen(true);
    setCurrentAction("Create Change Request");
    setOkBtnText("Submit Ticket");
  };

  const handleCancel = () => {
    setModalOpen(false);
    setCurrentAction("");
    setOkBtnText("");
    setTitle("");
    setCOMPID("");
    setPromoId("");
    setEventId("");
    setSku([]);
    setTicketDate(moment().format(DATE_FORMAT_DASH));
    setPriority(null);
    setTextArea("");
    setPromoName(null);
    setActionOwner(null);
    setErrorType(null);
    setEvent(null);
    setErrorTypeValueOther("");
    setTitleError("");
    setTicketDateError("");
    setErrorTypeError("");
    setPriorityError("");
    setCurrentRecord("");
    setPromoNameError("");
    setErrorTypeValueOtherError("");
    setActionOwnerError("");
    setMasterDataType(null);
    setMasterDataTypeLabel("SKU");
    setMasterSubData([]);
    setFile(null);
  };

  const handlePromoName = async (value) => {
    // const promo = promos.filter(v => v._id === value);
    // setCOMPID(promo[0]?.comp_id);
    // setPromoId(promo[0]?.promoId);
    setPromoNameError(value === null ? "This Field is Required" : "");
    setPromoName(value);
    await dispatch(getActionTeam(value));
    ticketReportService.getPromoDetails(value).then(response => {
      console.log(response, "s");

      setCOMPID(response.data.comp_id);
      setPromoDetails(response?.data)
      setPromoId(response.data.promoId);
      setPromoStatus(response.data.status)
      setUsers(response.data.stages)
      setActionOwner(null);
    });
  };

  const handleActionOwner = (value) => {
    setActionOwnerError(value === null ? "This Field is Required" : "");
    setActionOwner(value);
  };

  const handleTextArea = (e) => {
    const { value } = e.target;
    setTextArea(value);
  };

  const handleErrorTypeValueOther = (e) => {
    const { value } = e.target;
    setErrorTypeValueOtherError(
      Validator.validate("title", value, null, null, true)
    );
    setErrorTypeValueOther(value);
  };

  const handleSku = (value) => {
    // setSku(value);
    setMasterSubData(value);
  };
  const handlemasterSubData = (value) => {
    setMasterSubData(value);
  };
  const handleErrorType = (value) => {
    let errormsg =
      value === "" || value.length === 0 || value === null
        ? "Please Select Error Type"
        : "";
    if (value === "Other (please specify in comments)") {
      setErrorTypeError(errormsg);
      setErrorType(value);
    } else {
      setErrorTypeError(errormsg);
      setErrorType(value);
      setErrorTypeValueOther("");
      setErrorTypeValueOtherError("");
    }
  };
  const handleEventChange = (value) => {
    setEventError(value === "" || value === null ? "This Field is Required" : "");
    setEvent(value);
    const selectedEvent = promos.find((promo) => promo.event?._id === value);

    // Set the event ID if an event is found
    if (selectedEvent) {
      setEventId(selectedEvent.event?.eventId);
    } else {
      setEventId(null);
    }

    const promoList = [];
    for (let i = 0; i < promos.length; i++) {
      if (value && promos[i]?.event?._id === value) {
        promoList.push(<Option key={promos[i]._id}>{promos[i].name}</Option>);
      }
    }
    setpromoChildren(promoList);
    setPromoName(null);
    setActionOwner(null);
  }
  const handlePriority = (value) => {
    let errormsg =
      value === "" || value.length === 0 || value === null
        ? "Please Select Priority"
        : "";
    setPriorityError(errormsg);
    setPriority(value);
  };

  const handleDate = (value) => {
    setTicketDateError(Validator.validate("title", value, null, null, true));
    setTicketDate(value || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newRecord = {};
    if (currentAction === "Create Change Request") {
      if (
        titleError !== "" ||
        ticketDateError !== "" ||
        errorTypeError !== "" ||
        title === "" ||
        ticketDate === "" ||
        errorType === null ||
        eventError !== "" ||
        event === null ||
        promoNameError !== "" ||
        promoName === null ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOther === "") ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOtherError !== "") ||
        actionOwnerError !== "" ||
        actionOwner === null
      ) {
        setEventError(event === null || event === "" ? FIELD_REQUIRED : eventError);
        setTitleError(title === "" ? FIELD_REQUIRED : titleError);
        setPromoNameError(
          promoName === "" || promoName === null
            ? FIELD_REQUIRED
            : promoNameError
        );
        setActionOwnerError(
          actionOwner === "" || actionOwner === null
            ? FIELD_REQUIRED
            : actionOwnerError
        );
        setErrorTypeError(
          errorType === "" || errorType === null ? "This field is required" : ""
        );
        // setPriorityError(
        //   priority === "" || priority === null ? "This field is required" : ""
        // );
        setTicketDateError(
          ticketDate === "" ? FIELD_REQUIRED : ticketDateError
        );
        setErrorTypeValueOtherError(
          errorType === "Other (please specify in comments)" &&
            errorTypeValueOther === ""
            ? FIELD_REQUIRED
            : errorTypeValueOtherError
        );
        return;
      }
      setLoading(true);

      newRecord = {
        title: title,
        sku: sku,
        date: ticketDate,
        raisedBy: session?.fullName,
        priority: priority,
        additionalInfo: textArea,
        event: event,
        eventId: eventId,
        errorType:
          errorType === "Other (please specify in comments)"
            ? `${errorType} - ${errorTypeValueOther}`
            : errorType,
        status: "Open",
        promoName: promoName,
        actionOwner: actionOwner,
        masterDataType: masterDataType,
        masterDataTypeValue: masterSubData,
        media: fileDetails?._id,
        comp_id: comp_id
      };

      const response = await dispatch(
        createErrorTickets(
          newRecord.title,
          newRecord.sku,
          newRecord.date,
          newRecord.additionalInfo,
          newRecord.status,
          newRecord.priority,
          newRecord.errorType,
          newRecord.event,
          newRecord.raisedBy,
          newRecord.promoName,
          newRecord.actionOwner,
          newRecord.masterDataType,
          newRecord.masterDataTypeValue,
          newRecord.media,
          newRecord.comp_id,
          newRecord.eventId
        )
      );

      if (response.error === undefined) {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("success");
        setSuccessTicketId(response.payload.data.data[0].ticketNumber);
        setMessageModal("Your Ticket has been created");
        getData();
      } else {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("failed");
        setMessageModal(response?.error?.response?.data?.message);
      }
    }

    if (currentAction === "Update Change Request") {
      if (
        titleError !== "" ||
        ticketDateError !== "" ||
        errorTypeError !== "" ||
        title === "" ||
        ticketDate === "" ||
        errorType === null ||
        eventError !== "" ||
        event === null ||
        promoNameError !== "" ||
        promoName === null ||
        actionOwnerError !== "" ||
        actionOwner === null || event === null
      ) {
        setTitleError(title === "" ? FIELD_REQUIRED : titleError);
        setPromoNameError(promoName === null ? FIELD_REQUIRED : promoNameError);
        setActionOwnerError(
          actionOwner === "" || actionOwner === null
            ? FIELD_REQUIRED
            : actionOwnerError
        );
        setErrorTypeError(
          errorType === "" || errorType === null ? "This field is required" : ""
        );
        setEventError(event === "" || event === null ? FIELD_REQUIRED : eventError);
        setTicketDateError(
          ticketDate === "" ? FIELD_REQUIRED : ticketDateError
        );
        setErrorTypeValueOtherError(
          errorType === "Other (please specify in comments)" &&
            errorTypeValueOther === ""
            ? FIELD_REQUIRED
            : errorTypeValueOtherError
        );
        return;
      }
      setLoading(true);
      newRecord = {
        title: title,
        sku: sku,
        raisedBy: currentRecord?.raisedBy,
        priority: priority,
        additionalInfo: textArea,
        event: event,
        eventId: eventId,
        errorType:
          errorType === "Other (please specify in comments)"
            ? `${errorType} - ${errorTypeValueOther}`
            : errorType,
        status: currentRecord.ticketStatusValue,
        promoName: promoName,
        _id: currentRecord._id,
        actionOwner: actionOwner,
        masterDataType: masterDataType,
        masterDataTypeValue: masterSubData,
        media: fileDetails?._id,
        comp_id: comp_id
      };

      const response = await dispatch(
        updateErrorTicketById(
          newRecord.title,
          newRecord.sku,
          newRecord.additionalInfo,
          newRecord.status,
          newRecord.priority,
          newRecord.errorType,
          newRecord.event,
          newRecord.raisedBy,
          newRecord.promoName,
          newRecord.actionOwner,
          newRecord._id,
          newRecord.masterDataType,
          newRecord.masterDataTypeValue,
          newRecord.media,
          newRecord.comp_id,
          newRecord.eventId,
          newRecord.promoDetails
        )
      );

      if (response.error === undefined) {
        handleCancel();
        setLoading(false);
        // setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("success");
        setSuccessTicketId(response.payload.data.data.ticketNumber);
        setMessageModal("Ticket Updated Successfully");
        getData(filters, false);
      } else {
        const modalMessage =
          response?.error?.response?.data?.message ??
          "Something went wrong.Try again after sometime";
        setMessageModal(modalMessage);
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("failed");
      }
    }
  };

  const updateErrorTicket = async (record) => {
    console.log(record, "ll");

    await dispatch(getActionTeam(record.promoId));
    setErrorType(record?.errorTypeDetails?._id);
    setModalOpen(true);
    setCurrentAction("Update Change Request");
    setCurrentRecord(record);
    setTitle(record?.title);
    setCOMPID(record?.comp_id)
    setPromoDetails(record?.promoDetails)
    setPromoId(record?.promoDetails?.promoId);
    setSku(record.sku || []);
    setTicketDate(record?.date);
    setTextArea(record?.description || record?.additionalInfo);

    setPromoName(record?.promoName);
    setEvent({
      name: record?.eventDetails.name,
      value: record?.eventDetails._id,
      _id: record?.eventDetails._id,
    })
    setEventId(record?.eventDetails?.eventId)
    setMasterDataType(record?.masterDataType || []);
    setMasterSubData(record?.masterDataTypeValue || []);
    setActionOwner(record?.actionOwner);
    setMasterDataTypeLabel(record?.masterDataType || "SKU");
    setFile(record?.media)
    setOkBtnText("Save");
  };

  const viewErrorTicket = (record) => {
    navigate(`/ticket-report/${record._id}`);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    setSuccessTicketId("");
    setSuccessModalType("failed");
    setMessageModal("");
  };

  const deleteErrorTicket = (val) => {
    Modal.confirm({
      title: `Are you sure you want to delete error ticket. Ticket ID: #${val.ticketNumber} ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteErrorTicketYes(val),
      okType: "primary",
    });
  };

  const deleteErrorTicketYes = async (val) => {
    const response = await dispatch(deleteErrorTicketById(val._id));
    window.scrollTo(0, 0);
    const errorMessage = response?.error?.response?.data?.message;
    if (errorMessage) {
      showAlert({
        message: errorMessage,
        variant: AlertVariant.ERROR,
      });
    } else {
      showAlert({
        message: TextMessage.ERROR_TICKET_DELETE_TEXT,
        variant: AlertVariant.SUCCESS,
      });
    }
  };

  const handleReload = async () => {
    getData();
  };

  const setColumns = () => {
    let cols = [];

    TICKET_REPORT_LIST_COLUMNS.forEach((o) => {
      if (o.dataIndex === "date") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <DateFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else if (o.dataIndex === "validation") {
      } else if (o.dataIndex !== "priority" && o.dataIndex !== "status") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }

      if (tab === "Open" && o.dataIndex === "validation") {
        cols.push(o);
      }
    });
    return cols;
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const onSearch = async (value) => {
    setTableLoading(true);
    setSearchValue(value);
  };

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };

  const exportErrorReportData = async () => {
    // let data = {
    //   sortBy: "createdAt",
    //   orderBy: "DESC",
    //   search: searchValue || "",
    //   filters: {},
    // };
    setButtonLoading(true);
    const res = await dispatch(getAllErrorTickets({ export: true }));
    setCurrentSuccessMsg(res?.payload?.data?.message);
    setTimeout(() => {
      setCurrentSuccessMsg("");
    }, 6000);
    setButtonLoading(false);
  };


  return (
    <CommonLayout className="content-mr" breadCrumbText="Change Request">
      {currentSuccessMsg && (
        <Alert message={currentSuccessMsg} closable type="success" showIcon />
      )}
      {loadingState || reloadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="add-error-card">
            <div className="error-right-content">
              <Search
                placeholder="Search Error Ticket"
                enterButton
                className="searchErrorBtn"
                onSearch={onSearch}
                allowClear
                onChange={handleSearchChange}
              />
            </div>

            <div className="left-content">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="addErrorBtn"
                onClick={handleAdd}
              >
                Create Change Request
              </Button>
            </div>
          </div>

          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="error-table-card">
              <div className="error-table-topbar">
                <div className="errorbtnGrpWrapper">
                  <button
                    className={tab === "All Ticket" ? "btnStyle" : "btnStyle1"}
                    onClick={() => setTab("All Ticket")}
                  >
                    All Ticket
                  </button>
                  <button
                    className={tab === "Open" ? "btnStyle" : "btnStyle1"}
                    onClick={() => setTab("Open")}
                  >
                    Actionable
                  </button>
                </div>

                <div className="errorFilterGrpWrapper">
                  <Button
                    icon={
                      buttonLoading ? <LoadingOutlined /> : <ExportOutlined />
                    }
                    onClick={exportErrorReportData}
                    disabled={buttonLoading}
                  >
                    Export
                  </Button>
                  <div className="settingGrp">
                    <ReloadOutlined
                      onClick={handleReload}
                      className="reloadIconStyle"
                    />
                    <ColumnHeightOutlined
                      className="reloadIconStyle"
                      onClick={handleTableHeight}
                    />
                    <FullscreenOutlined
                      onClick={() => {
                        setIsEnter(true);
                      }}
                      className="reloadIconStyle"
                    />
                  </div>
                </div>
              </div>

              {/* table */}

              <div className="w-100">
                <CustomTable
                  isLoading={loadingState || tableLoading}
                  columns={setColumns()}
                  rows={setupRows(tickets)}
                  isEditable={false}
                  isDeleteable={false}
                  isViewable={false}
                  rowKey="_id"
                  onEdit={updateErrorTicket}
                  onDelete={deleteErrorTicket}
                  total={totalTickets?.total}
                  onView={viewErrorTicket}
                  size={tableSize}
                  onChange={async (
                    { current, pageSize },
                    filter = {},
                    { field, order, columnKey }
                  ) => {
                    const params = {
                      page: current,
                      perPage: pageSize,
                      sortBy: columnKey,
                      orderBy: order,
                    };
                    setCurrPage(current);
                    setPageSize(pageSize);
                    const keys = Object.keys(filter);
                    keys.forEach((key) => {
                      if (
                        (key === "title" ||
                          key === "promoName" ||
                          key === "adsetDetails.name" ||
                          key === "eventDetails.eventId" ||
                          key === "eventDetails.name" ||
                          key === "raisedBy" ||
                          key === "teamDetails.name" ||
                          key === "errorTypeDetails.name" ||
                          key === "ticketNumber" ||
                          key === "date" ||
                          key === "offerId" ||
                          key === "comp_id") &&
                        filter[key]
                      ) {
                        if (!params.search) {
                          params.search = {};
                        }
                        if (key === "teamDetails.name") {
                          params.search["teamDetails.name"] = filter[key];
                        } else if (key === "errorTypeDetails.name") {
                          params.search["errorTypeDetails.name"] = filter[key];
                        } else {
                          params.search[key] = filter[key];
                        }
                      } else {
                        if (filter[key]) {
                          if (!params.filters) {
                            params.filters = {};
                          }
                          if (key === "date") {
                            params.date = filter[key];
                          } else if (key === "status") {
                            params.filters[key] = filter[key][0].toLowerCase();
                          } else if (key === "priority") {
                            params.filters[key] = filter[key][0].toLowerCase();
                          } else {
                            params.filters[key] = filter[key];
                          }
                        }
                      }
                    });

                    setTableLoading(true);
                    setFilters(params);
                    getData(params, false);
                  }}
                />
              </div>

              <Modal
                open={modalOpen}
                title={currentAction}
                onOk={handleSubmit}
                onCancel={handleCancel}
                okText={okBtnText}
                destroyOnClose
                maskClosable={false}
                footer={
                  currentAction === "Create Change Request" ||
                    currentAction === "Update Change Request"
                    ? [
                      <Button onClick={handleCancel}>Cancel</Button>,
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="footerBtnNavy"
                      >
                        {loading ? "Loading..." : okBtnText}
                      </Button>,
                    ]
                    : null
                }
              >
                {currentAction === "Create Change Request" ||
                  currentAction === "Update Change Request" ? (
                  renderTag(currentRecord)
                ) : (
                  <></>
                )}
              </Modal>

              <Modal
                open={successModal}
                title={currentAction}
                destroyOnClose
                footer={null}
                closable={false}
              >
                {successModalType === "success" ? (
                  <div className="modalContentDiv">
                    <CircleTickIcon className="successIconStyle" />
                    <div className="descriptionDiv">
                      <span className="desctitleText">
                        {messageModal} Ticket ID: #{successTicketId}
                      </span>
                      <span className="dessubTitleText">
                        You’ll soon get response from our support team
                      </span>
                    </div>
                    <Button className="mt-30" onClick={handleCloseSuccessModal}>
                      Close
                    </Button>
                  </div>
                ) : (
                  <div className="modalContentDiv">
                    <CloseCircleOutlined className="failIconStyle" />
                    <div className="descriptionDiv">
                      <span className="desctitleText">
                        {messageModal
                          ? messageModal
                          : "Something went wrong. Try again after sometime."}
                      </span>
                    </div>
                    <Button className="mt-30" onClick={handleCloseSuccessModal}>
                      Close
                    </Button>
                  </div>
                )}
              </Modal>
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default TicketReport;
