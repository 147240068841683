import "./Sidebar.css";
import { Button, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { setCurrentSelectedPage } from "actions/app";
import { logout } from "actions/login";
import Logo from "image/logo.png";
import { MainMenu } from "utils/sidebar-menu";
import Scrollbars from "react-custom-scrollbars";
import session from "utils/session";

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state) => state.app);
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  // const isMerchant = session && session.role && session.role.toLowerCase() === 'merchant'
  const role = session && session.role ? session.role.toLowerCase() : "";
  const userPermissions =
    session && session.permissions ? session.permissions : [];
  const teamDetail = session && session.teamDetail ? session.teamDetail : null;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 500) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderSidebarItem = (item) => {
    const isPromoManagement = item.title === 'Promo Management';
    return (
      <Menu.Item key={item.id} icon={item.icon} disabled={!isPromoManagement && teamDetail?.isVCM}>
        <Link to={item.link}>{item.title}</Link>
      </Menu.Item>
    );
  };

  const renderSidebarSubmenu = (item) => {
    const isPromoManagement = item.title === 'Promo Management';
    return (
      <SubMenu key={item.id} title={item.title} icon={item.icon} disabled={!isPromoManagement && teamDetail?.isVCM}>
        {item.children.map((child) => {
          if (
            ["approver", "basic"].includes(role) &&
            ["map form builder", "form builder", "error-type"].includes(
              child.id
            )
          ) {
            return <></>;
          }
          if (
            child.id === "promo types" &&
            !userPermissions.includes("promoType") &&
            role !== "admin"
          ) {
            return <></>;
          }
          return (
            <Menu.Item key={child.id} disabled={!isPromoManagement && teamDetail?.isVCM}>
              <Link to={child.link}>{child.title}</Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  };

  const handleClick = (e) => {
    dispatch(setCurrentSelectedPage(e.key));
  };

  const handleLogout = async () => {
    const res = await dispatch(logout());
    if (res.error === undefined) {
      navigate("/login");
    }
  };

  const renderThumbVerticalView = ({ style, ...props }) => (
    <div
      {...props}
      style={{
        ...style,
        backgroundColor: "rgba(15, 42, 111, 1)",
        borderRadius: "inherit",
        cursor: "pointer",
      }}
    />
  );

  return (
    <Sider collapsible collapsed={collapsed} width="210px" trigger={null}>
      <div className="logo">
        {/* <img
          src={Logo}
          alt=""
          className={!collapsed ? "imgStyle" : "imgStyle-collapsed"}
        /> */}
      </div>
      <Scrollbars
        style={{ width: "100%", height: "calc(100vh - 141px)" }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        renderThumbVertical={renderThumbVerticalView}
      >
        {role === "admin" && (
          <Menu
            mode="inline"
            onClick={handleClick}
            defaultOpenKeys={MainMenu.map((o) => {
              return o.id;
            })}
            selectedKeys={currentPage}
          >
            {MainMenu.map((obj) => {
              if (obj.children.length !== 0) return renderSidebarSubmenu(obj);
              return renderSidebarItem(obj);
            })}
          </Menu>
        )}

        {role !== "admin" && (
          <Menu
            mode="inline"
            onClick={handleClick}
            defaultOpenKeys={MainMenu.map((o) => {
              return o.id;
            })}
            selectedKeys={currentPage}
          >
            {
              // eslint-disable-next-line array-callback-return
              MainMenu.map((obj) => {
                if (
                  obj.permissionId === "promo" ||
                  obj.permissionId === "report" ||
                  obj.permissionId === "notifications" ||
                  obj.permissionId === "calendar" ||
                  userPermissions.includes(obj.permissionId)
                ) {
                  if (obj.children.length !== 0)
                    return renderSidebarSubmenu(obj);
                  return renderSidebarItem(obj);
                }
              })
            }
          </Menu>
        )}
      </Scrollbars>
      <div className="sider-trigger">
        <div>
          {!collapsed ? (
            <Button
              type="text"
              icon={<LogoutOutlined className="logoutIconStyle" />}
              className="logoutBtnStyle"
              onClick={handleLogout}
            >
              Logout
            </Button>
          ) : (
            <LogoutOutlined
              className="logoutIconStyle"
              onClick={handleLogout}
            />
          )}
        </div>

        <div>
          <MenuFoldOutlined onClick={toggle} />
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
