import React, { useRef } from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import StaticElements from './StaticElements';

const SaticQuestionForm = forwardRef((props, ref) => {
    const { questions: staticQuestionFromProps } = props;
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});
    const [editAllowed, setEditAllowed] = useState(false);
    const dataArrayRef = useRef([]);
    const [isExtraSKUVisible, setExtraSKUVisible] = useState(false);
    useEffect(() => {
        setQuestions(staticQuestionFromProps);
    }, [staticQuestionFromProps]);

    useEffect(() => {
        if (props.questions && props.questions.length > 0 ) {
            props.questions.forEach((question) => {
                if (question.questionHeader === 'Price format and offer price') {
                    const answerValue = answers[question._id] || question.value;
                    if (answerValue === '15 - BXGY Percent Off' || answerValue === '13 - BXGY Free') {
                        setExtraSKUVisible(true);
                    }
                }
            });
        }
    }, [props.questions, answers, props.action]);

    const handleQuestionChange = (value, id, label) => {
        setAnswers((prevAnswers) => ({ ...prevAnswers, [id]: value }));
        if (label === 'Price format and offer price') {
            setExtraSKUVisible(value === '15 - BXGY Percent Off' || value === '13 - BXGY Free');
        }
        if (dataArrayRef.current) {
            dataArrayRef.current = dataArrayRef.current.filter(
                (item) => item.questionId !== id || value.includes(item.subOptionText)
            );
        }
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) => {
                if (question._id === id) {
                    const updatedOptionValue = question.optionValue.map((option) => {
                        if (option.optionText !== value) {
                            const { subOptions, ...rest } = option;
                            return { ...rest };
                        }
                        return option;
                    });

                    return { ...question, value, optionValue: updatedOptionValue };
                }
                return question;
            })
        );
    };
    const validateSubOptions = (question) => {
        let valid = true;
        const relevantDataArrayItems = dataArrayRef.current.filter(item => item.questionId === question._id);
        if (relevantDataArrayItems.length === 0 && question.optionValue?.length > 0) {
            setErrors((prev) => ({
                ...prev,
                [question._id]: 'Sub questions are required',
            }));
            valid = false;
        } else if (relevantDataArrayItems.length > 0) {
            if (typeof question?.value === 'string') {
                switch (question?.value?.trim()) {
                    case "12 - BXGX Percent Off":
                    case "15 - BXGY Percent Off":
                        if (relevantDataArrayItems.length !== 3) {
                            setErrors((prev) => ({
                                ...prev,
                                [question._id]: 'Sub questions are required',
                            }));
                            valid = false;
                        }
                        break;
                    case "13 - BXGY Free":
                    case "10 - BXGX Free":
                    case "21 - BX or More GX PP Grp":
                    case "20 - BX or More GX at PP Ea":
                    case "19 - BX or More GX Pct Off":
                    case "05 - Buy QTYX for PP Grp":
                        if (relevantDataArrayItems.length !== 2) {
                            setErrors((prev) => ({
                                ...prev,
                                [question._id]: 'Sub questions are required',
                            }));
                            valid = false;
                        }
                        break;
                    default:
                        question.optionValue.forEach(option => {
                            const subOptions = relevantDataArrayItems.filter(item => item.optionText === option.optionText);
                            if (question?.designate === "required" && subOptions.length > 0) {
                                subOptions.forEach(subOption => {
                                    if (subOption.subOptionValue.length === 0 || subOption.subOptionValue === '' || subOption.subOptionValue == null) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            [question._id]: 'Sub questions are required',
                                        }));
                                        valid = false;
                                    }
                                });
                            }
                        });
                        break;
                }
            } else if (Array.isArray(question?.value)) {
                if (relevantDataArrayItems.length !== question.value.length) {
                    setErrors((prev) => ({
                        ...prev,
                        [question._id]: 'Sub questions are required',
                    }));
                    valid = false;
                }
            }
        } else {
            question.optionValue.forEach(option => {
                const subOptions = relevantDataArrayItems.filter(item => item.optionText === option.optionText);
                if (question?.designate === "required" && subOptions.length > 0) {
                    subOptions.forEach(subOption => {
                        if (subOption.subOptionValue.length === 0 || subOption.subOptionValue == '' || subOption.subOptionValue == null) {
                            setErrors((prev) => ({
                                ...prev,
                                [question._id]: 'Sub questions are required',
                            }));
                            valid = false;
                        }
                    });
                }
            });
        }
        return valid;
    };

    useImperativeHandle(ref, () => ({

        isValid() {
            let valid = true;
            setErrors({});
            let offerPriceValue = null;
            if (props.action == 'create') {
                questions.forEach(q => {
                    if (q.questionHeader === 'Price format and offer price') {
                        offerPriceValue = answers[q._id] || q?.value;
                    }
                    if (q.questionHeader === 'Price format and offer price' && offerPriceValue == '06 - As advertised') {
                        return;
                    }
                    if (!(offerPriceValue == '13 - BXGY Free' || offerPriceValue == '15 - BXGY Percent Off') &&
                        (q.questionHeader == "SKU Input for BXGY BUY SKU" || q.questionHeader == "SKU Input for BXGY GET SKU")) {
                        return;
                    } else if ((offerPriceValue == '13 - BXGY Free' || offerPriceValue == '15 - BXGY Percent Off') &&
                        (q.questionHeader == "How are you entering the SKU information for this offer?")) {
                        return;
                    }
                    const answeredQuestion = answers[q._id] || q?.value;
                    if (answeredQuestion && answeredQuestion == "ECOM only") {
                        valid = true;
                        return false;

                    }
                    if (q?.designate === "required" && !answeredQuestion) {
                        setErrors((prev) => ({
                            ...prev,
                            [q._id]: "This field is required",
                        }));
                        valid = false;
                    } else {
                        if (q.designate === 'required' && q.questionHeader !== 'Is MAP override approved?' && !validateSubOptions(q)) {
                            valid = false;
                        }
                    }
                });
            }

            return valid;
        },
        getStaticQuestionData() {
            const currentQuestionData = questions.map((questionData) => {
                const questionId = questionData._id;
                const answerValue = answers[questionId];

                let updatedQuestion = {
                    ...questionData,
                    value: answerValue || questionData.value,
                };

                const relevantDataArrayItems = dataArrayRef.current.filter(item => item.questionId === questionId);
                if (relevantDataArrayItems.length > 0) {
                    updatedQuestion = {
                        ...updatedQuestion,
                        optionValue: updatedQuestion.optionValue.map(option => {
                            const matchingDataArrayItems = relevantDataArrayItems.filter(item => item.optionText === option.optionText);
                            const subOptions = matchingDataArrayItems.map(item => ({
                                subOptionText: item.subOptionText,
                                subOptionValue: item.subOptionValue,
                                subOptionLabel: item.subOptionLabel,
                                designate: questionData.designate,
                            }));

                            return matchingDataArrayItems.length > 0
                                ? { ...option, subOptions }
                                : option;
                        })
                    };
                }

                return updatedQuestion;
            });

            const finalData = questions.map((questionData) => {
                const questionId = questionData._id;
                const updatedQuestionData = currentQuestionData.find(q => q._id === questionId);

                return updatedQuestionData ? updatedQuestionData : questionData;
            });

            return finalData;
        }
    }));

    const handleSubOptionChange = (id, optionText, value, questionLabel, subOptionLabel) => {
        const tempObj = {
            questionId: id,
            subOptionText: optionText,
            subOptionValue: value,
            optionText: questionLabel,
            subOptionLabel: subOptionLabel ? subOptionLabel : ""
        };

        const existingObjIndex = dataArrayRef.current.findIndex(
            (obj) => obj.questionId === id && obj.optionText === questionLabel && obj.subOptionText === optionText
        );
        if (existingObjIndex !== -1) {
            dataArrayRef.current[existingObjIndex].subOptionValue = value;
            if (dataArrayRef?.current[existingObjIndex]?.subOptionValue?.length === 0) {
                dataArrayRef.current.splice(existingObjIndex, 1);
            }
        } else {
            dataArrayRef.current.push(tempObj);
        }
        // Update the questions array for the respective ID
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) => {
                if (question._id === id) {
                    return {
                        ...question,
                        optionValue: question?.optionValue?.map((option) => {
                            if (option.optionText === questionLabel) {
                                const updatedSubOptions = option?.subOptions
                                    ? option.subOptions.map((subOption) =>
                                        subOption.subOptionText === optionText
                                            ? { ...subOption, subOptionValue: value }
                                            : subOption
                                    )
                                    : [
                                        {
                                            subOptionText: optionText,
                                            subOptionValue: value,
                                            designate: question.designate,
                                            subOptionLabel: subOptionLabel ? subOptionLabel : ""
                                        },
                                    ];
                                return { ...option, subOptions: updatedSubOptions };
                            }
                            return option;
                        }),
                    };
                }
                return question;
            })
        );
    };

    return (
        <>
            {questions && questions.map((question) => {
                if (!isExtraSKUVisible && !(question.questionHeader === 'SKU Input for BXGY BUY SKU' || question.questionHeader === 'SKU Input for BXGY GET SKU')) {
                    return (
                        <StaticElements
                            id={question._id}
                            field={question?.questionType}
                            label={question?.questionHeader}
                            isDraft={props.isDraft}
                            isFirstTeam={props.isFirstTeam}
                            onChange={(value, id, ptId = "") =>
                                handleQuestionChange(value, question._id, question.questionHeader)
                            }
                            ans={question?.value}
                            options={question?.optionValue}
                            error={
                                question.designate === "required"
                                    ? errors[question._id]
                                    : ""
                            }
                            item={question}
                            designate={question.designate}
                            promoType={question.promoType}
                            handleSubOptionChange={handleSubOptionChange}
                            key={question._id}
                            action={
                                props.isDraft
                                    ? props.action
                                    : editAllowed
                                        ? props.action
                                        : props.action === "create"
                                            ? "create" : props.action === "edit" ? "edit"
                                                : "view"
                            }
                        />
                    )
                }
                if (isExtraSKUVisible && !(question.questionHeader === 'How are you entering the SKU information for this offer?')) {
                    return (
                        <StaticElements
                            id={question._id}
                            field={question?.questionType}
                            label={question?.questionHeader}
                            isDraft={props.isDraft}
                            isFirstTeam={props.isFirstTeam}
                            onChange={(value, id, questionLabel = "") =>
                                handleQuestionChange(value, question._id, question.questionHeader)
                            }
                            ans={question?.value}
                            options={question?.optionValue}
                            error={
                                question.designate === "required"
                                    ? errors[question._id]
                                    : ""
                            }
                            item={question}
                            designate={
                                props.action === "create"
                                    ? question.designate
                                    : ""
                            }
                            promoType={question.promoType}
                            handleSubOptionChange={handleSubOptionChange}
                            key={question._id}
                            action={
                                props.isDraft
                                    ? props.action
                                    : editAllowed
                                        ? props.action
                                        : props.action === "create"
                                            ? "create" : props.action === "edit" ? "edit"
                                                : "view"
                            }
                        />
                    )
                }
                return null;
            })}
        </>
    );
});

export default React.memo(SaticQuestionForm);
